.Container{
  margin-left: 10%;
  margin-right: 10%;
}

.accordion-button:not(.collapsed){
  background-color: rgba(121, 121, 121, 0.5);
  color: #FFFFFF;
}

.bg-body-tertiary{
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.accordion-button:focus{
  box-shadow: 0px 0px;
}

.nav-link{
  color: #0D303B;
}

.nav-link:hover{
  color: #0D303B;
}
.navbar-brand{
  color: #0D303B;
}

.nav-link.active{
  background-color: rgba(255, 255, 255, 0.7) !important;
  color: #0D303B !important;

}

.nav-link{
  background-color: rgba(255, 255, 255, 0.4) !important;
  color: #0D303B !important;
  border: 0px !important;
}

.nav-link.hover{
  background-color: rgba(255, 255, 255, 0.7) !important;
  border: transparent !important;
}

.nav-tabs{
  border-bottom: rgba(255, 255, 255, 0.7) 1px solid !important;
}

a {
  color: #3e257d !important;
}